(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo
   * @description
   *
   */
  angular
    .module('neo')
    .config(config);
  /* @ngInject */
  function config($mdThemingProvider) {
    $mdThemingProvider.definePalette('blueNeoPalette', {
      50: 'e3e9f1',
      100: 'b9c8dd',
      200: '8aa4c6',
      300: '5b7faf',
      400: '37639d',
      500: '14488c',
      600: '124184',
      700: '0e3879',
      800: '0b306f',
      900: '06215c',
      A100: '8eaaff',
      A200: '5b84ff',
      A400: '285eff',
      A700: '0f4bff',
      contrastDefaultColor: 'light',
      contrastDarkColors: [
        '50',
        '100',
        '200',
        'A100',
        'A200'
      ],
      contrastLightColors: [
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
        'A400',
        'A700'
      ]
    });

    $mdThemingProvider.definePalette('greenNeoPalette', {
      50: 'f2f8ed',
      100: 'dfefd3',
      200: 'c9e4b6',
      300: 'b3d998',
      400: 'a3d082',
      500: '93c86c',
      600: '8bc264',
      700: '80bb59',
      800: '76b44f',
      900: '64a73d',
      A100: 'fafff7',
      A200: 'd9ffc4',
      A400: 'b8ff91',
      A700: 'a7ff78',
      contrastDefaultColor: 'light',
      contrastDarkColors: [
        '50',
        '100',
        '200',
        '300',
        '400',
        'A100',
        'A200',
        'A400',
        'A700'
      ],
      contrastLightColors: [
        '500',
        '600',
        '700',
        '800',
        '900'
      ]
    });

    $mdThemingProvider.theme('default')
      .primaryPalette('blueNeoPalette', {
        default: '500',
        'hue-1': '300',
        'hue-2': '800',
        'hue-3': 'A100'
      })
      .accentPalette('greenNeoPalette', {
        default: '500',
        'hue-1': '300',
        'hue-2': '800',
        'hue-3': 'A100'
      })
      .warnPalette('red')
      .backgroundPalette('grey');
  }
}());
